/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('./resource/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./resource/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./resource/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./resource/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('./resource/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./resource/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./resource/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./resource/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('./resource/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./resource/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./resource/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./resource/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }