@import url("./static/fonts/pretendard/pretendardvariable-dynamic-subset.css");
@import url("./static/fonts/roboto/roboto.css");
@import url("./static/fonts/leferiPoint/leferiPoint.css");

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html, body {
	height: 100%;
}
html {
	font-size: 10px;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Text Editor */
.ql-toolbar {
  border-radius: 4px 4px 0 0 !important;
}
.ql-container {
  height: 342px !important;
  border-radius: 0 0 4px 4px !important;
}

.App, #root {
	height: 100%;
}

/* Editor에 border 생기는 이슈 있음 나중에 확인 할 것 */
/* NOTE: 텍스트 에디터 관련 CSS
* 사용 중 확인: 23.01.11
* 확인자 : 배철훈, 임현규
*/
/* 에디터 관련 */
.WorksDescriptionContainer h1 {
  font-size: 2em;
}

.WorksDescriptionContainer h2 {
  font-size: 1.5em;
}

.WorksDescriptionContainer h3 {
  font-size: 1.17em;
}

.WorksDescriptionContainer h4 {
  font-size: 1em;
}

.WorksDescriptionContainer h5 {
  font-size: 0.83em;
}

.WorksDescriptionContainer h6 {
  font-size: 0.67em;
}
