/* @font-face {
     font-family: 'LeferiPoint-BlackA';
     src: url('./resource/LeferiPointBlack.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
} */
@font-face {
     font-family: 'LeferiPoint-White';
     src: url('./resource/LeferiPointWhite.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
}

@font-face {
     font-family: 'LeferiPointSpecial';
     src: url('./resource/LeferiPointSpecial.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
}
@font-face {
     font-family: 'LeferiPointBase';
     src: url('./resource/LeferiBaseRegular.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
}